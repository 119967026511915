import { useCallback, useState } from 'react';
import { menu, cart } from '../../../assets/images/icons/index';
import cls from './HeaderMenuButtons.module.scss';
import { UnfocusableWrapper, PermissionWrapper, po, Button, RenderYesNoSwitcher } from '../../common';
import InfiniteScroll from 'react-infinite-scroll-component';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import { useMountedState } from 'react-use';
import classNames from 'classnames';
import parse, { domToReact, attributesToProps } from 'html-react-parser';
import { useNotifications } from '../../../context/notifications.context';
import ModulesMenu from '../../SideMenuEntries/ModulesMenu';
import ProcessMenu from '../../SideMenuEntries/ProcessMenu';
import { useNavigate } from 'react-router-dom';
import { setAllComponents, setCurrentTask, setProcessModule, setShowMenu, setShowSideMenu } from '../../../reduxStore/actions/flowActions';
import { processTaskAction, useProcessTaskContext } from '../../../context/processTask.context';
import { store } from '../../../reduxStore/store';
import { getCompanyDetails } from '../../../services/company.service';
import { first, take } from 'rxjs';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { app_max, APP_NAME, APPEND_TO_DATA, CLEAR_TO_SET_DATA } from '../../../shared/utility';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBell, faRectangleXmark, faXmark } from '@fortawesome/free-solid-svg-icons';
import './HeaderMenuButtons.css';
import { getAlComponentsByModuleId } from '../../../services/module.service';
import * as R from 'ramda';
import { now } from 'moment';
import { getUsersByPositions } from '../../../services/user.service';
import { getComponentById } from '../../../services/components.service';


const RenderNotification = props => {
	const isMounted = useMountedState();
	const [renderContext, , , , , onClickNotification] = useNotifications();
	const { notification } = props;
	const [reading, setReading] = useState(false);

	const onHrefClick = useCallback(
		async link => {
			const ackRead = await onClickNotification(notification.id);
			if (ackRead) {
				if (isMounted()) setReading(false);
				document.location.href = link;
			}
		},
		[notification.id, onClickNotification]
	);

	const onNotificationClick = async () => {

		setReading(true);
		if (notification.isUnread) {
			onClickNotification(notification.id);
		}
		if (notification?.objects?.length > 0)
			if (notification?.objects[0]?.type === 'component') {
				getComponentById(notification?.objects[0]?.id)
					.pipe(first())
					.subscribe({
						next: () => { },
						error: () => { }
					});
			}
		if (isMounted()) setReading(false);
	};

	if (renderContext.renderUnreadOnly && !notification.isUnread) {
		return null;
	}
	return (
		<button className={classNames(cls.notification)} app-is-unread={notification.isUnread.toString()} onClick={onNotificationClick}>
			{parse(notification.message, {
				replace: domNode => {
					if (domNode.name === 'a' && domNode.attribs && domNode.attribs.href) {
						const link = attributesToProps(domNode.attribs).href;
						delete domNode.attribs.href;
						return (
							<a
								onClick={event => {
									event.preventDefault();
									event.stopPropagation();
									setReading(true);
									onHrefClick(link);
								}}
							>
								{domToReact(domNode?.children)}
							</a>
						);
					}
				},
			})}

		</button>
	);
};
const RenderNotifications = () => {
	const [renderContext, loading, onReadAllPressed, onShowOnlyUnread, onLoadMore] = useNotifications();
	return (
		<div className={cls.renderNotificationsContainer}>
			<div className={cls.titleContainer}>
				<p className={cls.title}>{'Notifications'}</p>

			</div>
			<InfiniteScroll
				dataLength={renderContext.renderNotifications.length}
				next={onLoadMore}
				scrollThreshold="200px"
				initialScrollY={0}
				hasMore={renderContext.canFetchMore}
				height={300}
			>
				<div key={'notifContainer' + loading} style={{ flex: 1 }}>
					{renderContext.renderNotifications?.length > 0 && (
						renderContext.renderNotifications?.map(i => <RenderNotification key={i.id} notification={i} />)
					)}


				</div>
			</InfiniteScroll>

			<RenderYesNoSwitcher
				title={'Show only unread?'}
				style={{ margin: '50px 0px 20px 10px', width: 'auto' }}
				value={renderContext.renderUnreadOnly}
				toggleSelected={val => {
					onShowOnlyUnread({ target: { checked: val } })
				}}
			/>
			<RenderYesNoSwitcher
				title={'Mark all as read?'}
				style={{ margin: '20px 0px 20px 10px', width: 'auto' }}
				value={false}
				toggleSelected={val => {
					if (val) {
						onReadAllPressed();
					}

				}}
			/>

		</div>
	);
};
export const NotificationsMenuEntry = _props => {
	const [renderContext, , , , , , setListVisible] = useNotifications();
	const [show, setShow] = useState(false);

	return (
		<UnfocusableWrapper /*onMouseEnter={() => setShow(true)}*/ onClickOutside={() => setShow(false)} className={cls.menuEntryHost} /*app-button-variant={show ? 'hovered' : ''}*/>
			<button type={'button'} className={cls.menuButton} onClick={() => setShow(!show)}>
				<FontAwesomeIcon icon={faBell} style={{ color: 'gray', width: '20px', height: '20px' }} />
				{renderContext.unreadNotificationsCount.counted > 0 && (
					<div className={cls.notificationsUnread}>
						<p>{renderContext.unreadNotificationsCount.counted}</p>
					</div>
				)}

			</button>
			<div className={`mobile-navbar ${show ? 'open-nav' : ''}`} style={{ width: '100%', backgroundColor: 'transparent' }}>
				<div className={`mobile-navbar ${show ? 'open-nav' : ''}`} style={{ height: 'inherit', width: '100%', boxShadow: '0px 2px 20px 5px rgba(0, 0, 0, 0.1)', }}>
					<div onClick={() => setShow(false)} className="mobile-navbar-close">
						<FontAwesomeIcon icon={faRectangleXmark} className="hamb-icon" />
					</div>
					<RenderNotifications />

				</div>

			</div>


		</UnfocusableWrapper>
	);
};

export const CartMenuEntry = props => {
	const { orders, companyData } = props;
	const [hoover, setHoover] = useState(false);
	const navigate = useNavigate();
	const [, setTaskProcessContext] = useProcessTaskContext();

	return (
		<div
			onMouseEnter={() => setHoover(true)}
			onMouseLeave={() => setHoover(false)}
			className={cls.modulesEntryHost}
			style={{ height: '40px' }}
			app-button-variant={hoover ? 'hovered' : ''}
			onClick={() => {
				companyData.start = false;
				getCompanyDetails().pipe(first()).subscribe({});
				store.dispatch(setCurrentTask(undefined));

				store.dispatch(setProcessModule({ ...orders.begunModule }));
				setTaskProcessContext({
					action: processTaskAction.setModuleItems,
					value: orders.items,
				});

				setTaskProcessContext({
					action: processTaskAction.setModuleNewStepComponents,
					value: orders.newStepComponents,
				});
				setTaskProcessContext({
					action: processTaskAction.setComponents,
					value: orders.components,
				});
				navigate('/begin-module');
			}}
		>
			<Button id={'mainC'} type={'button'} onClick={() => setHoover(c => !c)}>
				{orders?.items?.length}
				<img src={cart} style={{ padding: '0px 0 10px 0', width: '30px', height: '40px' }} alt="processmaker-menu" />
			</Button>
			<div className={cls.modulesContainer} style={{ fontSize: '14px', textAlign: 'left', paddingLeft: '10px' }}>
				There are {orders?.items?.length} item (s) on cart, click to continue this transaction.
			</div>
		</div>
	);
};
export const MainMenuEntry = props => {
	const { setUserLogout, onAboutClick, logo, showBilling = true } = props;
	const [hoover, setHoover] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [showModule, setShowModule] = useState(false);
	return (
		<div onMouseEnter={() => document.getElementById('mainB').click()} onMouseLeave={() => setHoover(false)} className={cls.modulesEntryHost} app-button-variant={hoover ? 'hovered' : ''}>
			{!isMobile ? (
				<button id={'mainB'} className={cls.modulesButton} type={'button'} onClick={() => setHoover(c => !c)}>
					{(logo && !hoover && !hoover) ? logo : <FontAwesomeIcon icon={faBars} style={{ color: 'var(--app-color-gray-lighter)' }} />}
				</button>
			) : (
				<img src={menu} style={{ margin: '0 20px 0 20px', width: '20px', height: '20px' }} alt="processmaker-menu" onClick={() => setHoover(c => !c)} />
			)}
			{hoover && !isMobile && <div className={cls.racordM} />}
			<div className={cls.modulesContainer} style={{ textAlign: 'left', width: isMobile ? '400px' : '' }}>
				{isMobile && (
					<button
						type={'button'}
						className={cls.logoutButton}
						onClick={() => {
							dispatch(setShowSideMenu(true));
							isMobile && setHoover(false);
							navigate('/dashboard');
						}}
					>
						<p style={{ fontSize: '16px', fontWeight: '600' }}>{'Dashboard'}</p>
					</button>
				)}
				{isMobile && (
					<button
						type={'button'}
						className={cls.logoutButton}
						onClick={() => {
							setShowModule(!showModule);
						}}
					>
						<p style={{ fontSize: '16px', fontWeight: '700' }}>{'Module'}</p>
					</button>
				)}

				{isMobile && showModule && (
					<>
						<hr />
						<ModulesMenu />
						<hr />
					</>
				)}
				<button
					type={'button'}
					className={cls.logoutButton}
					onClick={() => {
						dispatch(setShowSideMenu(false));
						isMobile && setHoover(false);
						navigate('/my-profile');
					}}
				>
					<p style={{ fontSize: '16px', fontWeight: '700' }}>{'Profile'}</p>
				</button>
				<PermissionWrapper permissions={[po.billing.view]}>
					<button
						type={'button'}
						className={cls.logoutButton}
						onClick={() => {
							dispatch(setShowSideMenu(false));
							isMobile && setHoover(false);
							navigate('/users');
						}}
					>
						<p style={{ fontSize: '16px', fontWeight: '700' }}>{'Users'}</p>
					</button>
				</PermissionWrapper>
				<button
					type={'button'}
					className={cls.logoutButton}
					onClick={() => {
						dispatch(setShowSideMenu(false));
						isMobile && setHoover(false);
						navigate('/company');
					}}
				>
					<p style={{ fontSize: '16px', fontWeight: '700' }}>{'Company'}</p>
				</button>
				{!isMobile && showBilling && (
					<button
						style={{ display: '' }}
						type={'button'}
						className={cls.logoutButton}
						onClick={() => {
							dispatch(setShowSideMenu(false));
							navigate('/billing-info');
						}}
					>
						<p style={{ fontSize: '16px', fontWeight: '700' }}>{'Billing'}</p>
					</button>
				)}
				<button
					type={'button'}
					className={cls.logoutButton}
					onClick={() => {
						setHoover(false);
						onAboutClick();
					}}
				>
					<p style={{ fontSize: '16px', fontWeight: '700' }}>{'About'}</p>
				</button>
				<hr />
				<button
					type={'button'}
					className={cls.logoutButton}
					onClick={() => {
						setHoover(false);
						setUserLogout(true);
					}}
				>
					<p style={{ fontSize: '16px', fontWeight: '700' }}>{'Logout'}</p>
				</button>
			</div>
		</div>
	);
};

export const MobileMainMenuEntry = props => {
	const { setUserLogout, onAboutClick, onTransactionsClick } = props;
	const [hoover, setHoover] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [showModule, setShowModule] = useState(false);
	return (
		<div onMouseEnter={() => document.getElementById('mainB').click()} onMouseLeave={() => setHoover(false)} className={cls.modulesEntryHost} app-button-variant={hoover ? 'hovered' : ''}>
			{hoover && !isMobile && <div className={cls.circle} />}
			{!isMobile ? (
				<button id={'mainB'} className={cls.modulesButton} type={'button'} onClick={() => setHoover(c => !c)}>
					<FontAwesomeIcon icon={faBars} />
				</button>
			) : (
				<FontAwesomeIcon icon={faBars} style={{ margin: '0 20px 0 20px', width: '20px', height: '20px' }} onClick={() => setHoover(c => !c)} />
			)}
			{hoover && !isMobile && <div className={cls.racordM}></div>}
			<div className={cls.modulesContainer} style={{ paddingLeft: '10px', paddingRight: '10px', textAlign: 'left', width: isMobile ? '360px' : '' }}>
				<button
					type={'button'}
					className={cls.logoutButton}
					onClick={() => {
						dispatch(setShowSideMenu(false));
						isMobile && setHoover(false);
						onTransactionsClick();
					}}
				>
					<p style={{ fontSize: '18px', fontWeight: 'normal' }}>{'Transactions'}</p>
				</button>
				<button
					type={'button'}
					className={cls.logoutButton}
					onClick={() => {
						dispatch(setShowSideMenu(false));
						isMobile && setHoover(false);
						navigate('/my-profile');
					}}
				>
					<p style={{ fontSize: '18px', fontWeight: 'normal' }}>{'Profile'}</p>
				</button>
				{!isMobile && (
					<PermissionWrapper permissions={[po.billing.view]}>
						<button
							style={{ display: '' }}
							type={'button'}
							className={cls.logoutButton}
							onClick={() => {
								dispatch(setShowSideMenu(false));
								navigate('/billing-info');
							}}
						>
							<p style={{ fontSize: '18px', fontWeight: 'normal' }}>{'Billing'}</p>
						</button>
					</PermissionWrapper>
				)}
				<button
					type={'button'}
					className={cls.logoutButton}
					onClick={() => {
						setHoover(false);
						onAboutClick();
					}}
				>
					<p style={{ fontSize: '18px', fontWeight: 'normal' }}>{'About'}</p>
				</button>
				<hr />
				<button
					type={'button'}
					className={cls.logoutButton}
					onClick={() => {
						setHoover(false);
						setUserLogout(true);
					}}
				>
					<p style={{ fontSize: '18px', fontWeight: 'normal' }}>{'Logout'}</p>
				</button>
			</div>
		</div>
	);
};
export const ModulesMenuEntry = () => {
	const [hoover, setHoover] = useState(false);

	return (
		<div onMouseEnter={() => document.getElementById('modulesB').click()} onMouseLeave={() => setHoover(false)} className={cls.modulesEntryHost} app-button-variant={hoover ? 'hovered' : ''}>
			{/* {hoover && <div className={cls.circle} />} */}
			<button id={'modulesB'} className={cls.modulesButton} type={'button'} onClick={() => setHoover(c => !c)}>
				Modules
			</button>
			{hoover && <div className={cls.racordM}></div>}
			<div className={cls.modulesContainer}>
				<ModulesMenu />
			</div>
		</div>
	);
};

export const ProcessMenuEntry = () => {
	const [hoover, setHoover] = useState(false);

	return (
		<div onMouseEnter={() => document.getElementById('processB').click()} onMouseLeave={() => setHoover(false)} className={cls.modulesEntryHost} app-button-variant={hoover ? 'hovered' : ''}>
			<button id={'processB'} className={cls.modulesButton} type={'button'} onClick={() => setHoover(c => !c)}>
				{'Workflows'}
			</button>
			{hoover && <div className={cls.racordM}></div>}
			<div className={cls.modulesContainer}>
				<ProcessMenu />
			</div>
		</div>
	);
};

export const DashboardMenuEntry = () => {
	const [hoover, setHoover] = useState(false);
	const navigate = useNavigate();

	return (
		<div onMouseLeave={() => setHoover(false)} className={cls.modulesEntryHost} style={{ marginRight: '6px', color: 'inherit' }} app-button-variant={hoover ? 'hovered' : ''}>
			<Button
				clear
				title={'Dashboard'}
				titleStyle={{ fontFamily: 'var(--app-text-open-sans)', fontSize: '18px', color: 'white', letterSpacing: '-1px' }}
				titleContainer={cls.dashboardButton}
				style={{ backgroundColor: 'transparent', margin: '12px 0px 12px 0px' }}
				clicked={() => {
					navigate('/dashboard');
				}}
			/>
		</div>
	);
};

export const HelloNameMenuEntry = props => {
	const [hoover, setHoover] = useState(false);

	const navigate = useNavigate();

	return (
		<div >
			<Button
				clear
				title={props.message}
				titleStyle={{ fontFamily: 'var(--app-text-open-sans)', fontSize: '18px', color: 'white', letterSpacing: '-1px' }}
				titleContainer={cls.dashboardButton}
				style={{ backgroundColor: 'transparent', margin: '12px 0px 12px 0px' }}
				clicked={() => {
					store.dispatch(setShowSideMenu(false));
					navigate('/my-profile');
				}}
			/>

		</div>
	);
};

export const SingleNavigateModule = props => {
	const [hoover, setHoover] = useState(false);
	const { components, lastUpdates, allModules, blueprints } = useSelector(store => store.flowReducer);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const process = props.companyProcesses.find(pr => pr.id === props?.begunModule?.processModule.id);
	return (
		<div onMouseLeave={() => setHoover(false)} app-button-variant={hoover ? 'hovered' : ''}>
			{loading && <Button title={props.title} loading={loading} />}
			{!loading && <button
				id={'ModuleSB'}
				className={cls.dashboardButton}
				type={'button'}
				onClick={() => {

					store.dispatch(
						setProcessModule({
							...(process ? process : props?.begunModule?.processModule),
							stepIndex: 0,
							id: props.module.procedure.id,
							module_id: props.module.id,
							module: props.module,
							isUpdating: undefined,
						})
					);

					if (components.filter(co => co?.module_id === props.module.id && co?.component_fields?.length == 0).length > 0 || components.filter(co => co?.module_id === props.module.id).length === 0) {
						setLoading(true);
						const module = allModules.find(m => m.id === props.module.id);

						if (module?.options?.positions?.length > 0) {
							getUsersByPositions(module?.options?.positions)
								.pipe(take(1))
								.subscribe({});
						}

						lastUpdates.componentsByModule[module.name] = 0;
						getAlComponentsByModuleId(module, APPEND_TO_DATA, app_max, 1)
							.pipe(take(1))
							.subscribe({
								next: data => {
									const components_data = data?.data ? data?.data : data;

									store.dispatch(setAllComponents(R.sortBy(R.prop('created_date'), [...components.filter(c => c.module_id !== module.id), ...components_data]), CLEAR_TO_SET_DATA, blueprints.map(bp => bp.fields).flat(1)));

									lastUpdates.componentsByModule[module.name] = now();
									module.lastUpdate = now();
									module.count = data?.total ?? 0;

									if (module?.options?.supporting?.length > 0) {
										module?.options?.supporting?.filter((support, index) => {

											getAlComponentsByModuleId({ id: support, name: `${allModules?.find(mod => mod.id === support)?.name}${index}` ?? `${module?.name}${index}` }, APPEND_TO_DATA, app_max, 1)
												.pipe(take(1))
												.subscribe({
													next: (data) => {

														if (module?.options?.supporting?.length == index + 1) {
															setLoading(false);
															store.dispatch(setShowMenu(false));
															navigate('/begin-module');
														}

													},
													error: () => setLoading(false)
												})
										});
									} else {
										setLoading(false);
										store.dispatch(setShowMenu(false));
										navigate('/begin-module');
									}

								},
								complete: () => { },
								error: () => setLoading(false),
							});

					} else {
						store.dispatch(setShowMenu(false));
						navigate('/begin-module');
					}
				}}
			>
				{props.title}
			</button>}
			{hoover && <div className={cls.racordM}></div>}






		</div>
	);
};

export const HomeBarMenu = props => {
	const [hoover, setHoover] = useState(false);

	const navigate = useNavigate();
	return (
		<div onMouseLeave={() => setHoover(false)} className={cls.modulesEntryHost} app-button-variant={hoover ? 'hovered' : ''}>
			<button id={'HelloB'} className={cls.dashboardButton} type={'button'} onClick={() => navigate(props.link)}>
				{props.message}
			</button>
			{hoover && <div className={cls.racordM}></div>}
		</div>
	);
};

export const MainHomeBarMenuEntry = props => {
	const [hoover, setHoover] = useState(false);
	const navigate = useNavigate();
	return (
		<div onMouseEnter={() => document.getElementById('mainB').click()} onMouseLeave={() => setHoover(false)} className={cls.modulesEntryHost} app-button-variant={hoover ? 'hovered' : ''}>
			{/* {hoover && !isMobile && <div className={cls.circle} />} */}
			{!isMobile ? (
				<button id={'mainB'} className={cls.modulesButton} type={'button'} onClick={() => setHoover(c => !c)}>
					<img src={menu} style={{ padding: isMobile ? '0px' : '2px', width: '20px', height: '20px' }} alt="processmaker-menu" />
				</button>
			) : (
				<img src={menu} style={{ margin: '0 20px 0 20px', width: '20px', height: '20px' }} alt="processmaker-menu" onClick={() => setHoover(c => !c)} />
			)}
			{hoover && !isMobile && <div className={cls.racordM}></div>}
			<div className={cls.modulesContainer} style={{ textAlign: 'left', width: isMobile ? '360px' : '' }}>
				<button
					type={'button'}
					className={cls.logoutButton}
					onClick={() => {
						navigate('/');
					}}
				>
					<p style={{ fontSize: '16px', fontWeight: '700' }}>{'Home'}</p>
				</button>

				<button
					type={'button'}
					className={cls.logoutButton}
					onClick={() => {
						navigate('/flowhero');
					}}
				>
					<p style={{ fontSize: '16px', fontWeight: '700' }}>{APP_NAME}</p>
				</button>
				<button
					type={'button'}
					className={cls.logoutButton}
					onClick={() => {
						navigate('/flowhero');
					}}
				>
					<p style={{ fontSize: '16px', fontWeight: '700' }}>{'Register'}</p>
				</button>
			</div>
		</div>
	);
};
