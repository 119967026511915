import { getAllComponentBlueprints, getFieldTypes } from './components.service';
import { getCompanyProcesses } from './processes.service';
import { getAllModules } from './module.service';
import { APPEND_TO_DATA, CLEAR_TO_SET_DATA } from '../shared/utility';
import { Observable, take } from 'rxjs';
import { getAllCompanyPositionsAsArray } from './company.service';
import { getAllUsers } from './user.service';
import { PAYMONGO_KEY, URLS } from './API_CONSTANTS';
import { getSubscriptionPlans } from './payments.service';
import { getAllCompletedTasks, getAllTasks } from './tasks.service';
import { store } from '../reduxStore/store';
import { axiosInstance } from './axiosInstance';

export const getMasterData = (userOnly = false) => {
	const { authenticatedUser } = store.getState().authReducer;
	if (userOnly === false) {
		getAllComponentBlueprints(CLEAR_TO_SET_DATA)
			.pipe(take(1))
			.subscribe({
				complete: () => {
					getFieldTypes()
						.pipe(take(1))
						.subscribe({
							complete: () => {
								getAllModules(APPEND_TO_DATA).pipe(take(1)).subscribe({});
								getAllCompanyPositionsAsArray().pipe(take(1)).subscribe({
									next: () => getAllTasks(authenticatedUser.id)
										.pipe(take(1))
										.subscribe({
											complete: () => getAllCompletedTasks(CLEAR_TO_SET_DATA, authenticatedUser.id).pipe(take(1)).subscribe({}),
										})
								})
							},
						});
				},
			});
	} else {
		getAllUsers().pipe(take(1)).subscribe({});
	}

	if (PAYMONGO_KEY !== '') {
		getSubscriptionPlans().pipe(take(1)).subscribe({});
	}
};

export const getMobileData = () => {
	const { authenticatedUser } = store.getState().authReducer;
	getAllComponentBlueprints(CLEAR_TO_SET_DATA).pipe(take(1)).subscribe({});
	getAllCompanyPositionsAsArray().pipe(take(1)).subscribe({
		next: () => getAllTasks(authenticatedUser.id)
			.pipe(take(1))
			.subscribe({
				complete: () => getAllCompletedTasks(CLEAR_TO_SET_DATA, authenticatedUser.id).pipe(take(1)).subscribe({}),
			})
	})
};

export const getData = (url, headers) => {

    return new Observable(subscriber => {
        URLS.basic.getData2(url, headers)
            .then((data) => subscriber.next(data))
            .catch((error) => {
                subscriber.error(error);
            });
    });
   
};

