import { useNavigate } from 'react-router-dom';
import { userLogout } from '../../../services/auth.service';
import { ModalR } from '../index';
import Button from '../UI/Button/Button';
import cls from './LogoutModal.module.scss';
import { isMobile } from 'react-device-detect';
import { useState } from 'react';
const LogoutModal = props => {
	const navigate = useNavigate();
	const { openModal, setOpenModal, slug } = props;
	const [loading, setLoading] = useState(false);
	const userLogoutHandler = () => {
		setLoading(true);
		userLogout(false).subscribe({
			next: () =>  slug ? navigate(`/pages/home/${slug}`) :  navigate('/login'),
			error: () => setLoading(false),
		});
	};
	const triggerCloseModal = () => setOpenModal(false);
	return (
		<>
			<ModalR isOpen={openModal} onClose={triggerCloseModal} backdropColor={true} blured={true} scrollable={true} shadowedContainerStyle={{ border: '5px black', width: isMobile ? '92%' : '20%', minWidth: '300px', backgroundColor: loading ? 'transparent' : '' }}>
				<div style={{ display: loading ? 'none' : '' }}>
					<p className={cls.logoutText}>{'Are you sure you want to log out?'}</p>
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<Button title={'Logout'} clicked={() => userLogoutHandler()} loading={loading} />
						<Button title={'No, return back'} light clicked={triggerCloseModal} />
					</div>
				</div>
			</ModalR>
		</>
	);
};

export default LogoutModal;
