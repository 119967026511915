import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setMessageBar, setOnBoardingComplete, setShowMenu, setShowSideMenu } from '../../../reduxStore/actions/flowActions';
import { setIsOnboarding, setOnBoardingModalsVisibility, setOnBoardingVisible } from '../../../reduxStore/actions/modalsActions';
import BasicCompanyInformation from './BasicCompanyInformation';
import OnboardingNewProcess from './OnboardingNewProcess';
import OnboardingNewModule from './OnboardingNewModule';
import OnboardingNewBlueprint from './OnboardingNewBlueprint';
import OnboardingNewStep from './OnboardingNewStep';
import Intro from './Intro';
import cls from './OnBoardingModals.module.scss';
import CreateDepartments from './CreateDepartments';
import AddNewPositionModal from './AddNewPositionModal';
import OnboardingOrgChart from './OnboardingOrgChart';
import { getAllComponentBlueprints, getFieldTypes } from '../../../services/components.service';
import { APPEND_TO_DATA, APP_NAME, CLEAR_TO_SET_DATA, PROMO_VIDEO, WHAT_ARE_WORKFLOWS } from '../../../shared/utility';
import { first, take } from 'rxjs';
import { pat, useProcessContext } from '../../../context/processStep.context';
import { bcat, useBlueprintComponentsContext } from '../../../context/blueprintsComponents.context';
import { getMasterData } from '../../../services/master.service';
import { startTrial } from '../../../services/payments.service';
import { getCompanyProcesses } from '../../../services/processes.service';
import { useEffect, useState } from 'react';
import { userLogout } from '../../../services/auth.service';
import CompanyOrganizationalChart from '../CompanyOrganizationalChart/CompanyOrganizationalChart';
import ProcessStepsChart from '../../SideMenuEntries/BlueprintsComps/ProcessesTab/ProcessStepsChart';

const OnboardingModals = props => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { onClose } = props;

	const { onBoardingModalsVisibility, isOnboarding } = useSelector(store => store.modalsReducer);
	const { selectedProcess, companyPositionsArray } = useSelector(store => store.flowReducer);
	const { authenticatedUser } = useSelector(store => store.authReducer);

	const [, setInProcContext] = useProcessContext();
	const [, setBpContext] = useBlueprintComponentsContext();
	const [skipped, setSkipped] = useState(false);
	const [loading, setLoading] = useState(false);

	const {
		firstModalVisible,
		secondModalVisible,
		thirdModalVisible,
		fourthModalVisible,
		fifthModalVisible,
		sixthModalVisible,
		seventhModalVisible,
		eightModalVisible,
		ninthModalVisible,
		tenthModalVisible,
		eleventhModalVisible,
		twelvthModalVisible,
		lastModalVisible,
		positionModalVisible,
		thirteenthModalVisible,
	} = onBoardingModalsVisibility;

	useEffect(() => {
		dispatch(setShowSideMenu(false));
		dispatch(setShowMenu(false));
	}, [onBoardingModalsVisibility, isOnboarding]);

	const toggleFirstModal = () => {
		dispatch(setOnBoardingModalsVisibility({ firstModalVisible: true }));
	};
	const toggleSecondModal = () => {
		dispatch(setOnBoardingModalsVisibility({ secondModalVisible: true }));
	};
	const toggleThirdModal = () => {
		dispatch(setOnBoardingModalsVisibility({ thirdModalVisible: true }));
	};
	const toggleFourthModal = () => {
		dispatch(setOnBoardingModalsVisibility({ fourthModalVisible: true }));
	};

	const toggleFifthModal = () => {
		dispatch(setOnBoardingModalsVisibility({ fifthModalVisible: true }));
	};
	const toggleSixthModal = () => {
		dispatch(setOnBoardingModalsVisibility({ sixthModalVisible: true }));
	};
	const toggleSeventhModal = () => {
		dispatch(setOnBoardingModalsVisibility({ seventhModalVisible: true }));
	};
	const toggleEightModal = () => {
		dispatch(setOnBoardingModalsVisibility({ eightModalVisible: true }));
	};
	const toggleNinthModal = () => {
		dispatch(setOnBoardingModalsVisibility({ ninthModalVisible: true }));
	};
	const toggleTenthModal = () => {
		dispatch(setOnBoardingModalsVisibility({ tenthModalVisible: true }));
	};
	const toggleEleventhModal = () => {
		dispatch(setOnBoardingModalsVisibility({ eleventhModalVisible: true }));
	};
	const toggleTwelvthModal = () => {
		dispatch(setOnBoardingModalsVisibility({ twelvthModalVisible: true }));
	};
	const toggleThirteenthModal = () => {
		dispatch(setOnBoardingModalsVisibility({ thirteenthModalVisible: true }));
	};
	const toggleLastModal = () => {
		dispatch(setOnBoardingModalsVisibility({ lastModalVisible: true }));
	};

	const toggleNoModal = () => {
		dispatch(setOnBoardingModalsVisibility({}));
	};
	const onCancel = () => {
		onClose();
	};

	return (
		<div>
			{firstModalVisible && (
				<Intro
					title={`1. Welcome to ${APP_NAME}, ${authenticatedUser?.first_name} ${authenticatedUser?.last_name}`}
					subtitle={''}
					video={WHAT_ARE_WORKFLOWS}
					checkbox={
						<>
							{`By using ${APP_NAME}, you agree with our `}
							<a href={`https://www.flowhero.app/${APP_NAME}/privacy-policy`}>Terms and Conditions</a> and <a href={`https://www.flowhero.app/${APP_NAME}/privacy-policy`}>Privacy Policy</a>
						</>
					}
					modalVisible={firstModalVisible}
					shadowedContainerStyle={{ width: '100%', height: '100%' }}
					modalWrapperStyle={{ width: '100%', height: '100%' }}
					childrenWrapperStyle={{}}
					onContinueButtonAction={toggleSecondModal}
					onCancel={() => {}}
				/>
			)}
			{secondModalVisible && (
				<BasicCompanyInformation modalVisible={secondModalVisible} shadowedContainerStyle={{ width: '100%', height: 'auto', minHeight: '100%' }} onPreviousButtonAction={toggleFirstModal} onContinueButtonAction={toggleThirdModal} onNextButtonAction={toggleThirdModal} />
			)}

			{thirdModalVisible && (
				<CreateDepartments
					hasTutorial
					modalVisible={thirdModalVisible}
					hasCloseButton={!isOnboarding}
					modalWrapperStyle={{}}
					shadowedContainerStyle={{ width: '100%', height: '100%' }}
					childrenWrapperStyle={{}}
					onPreviousButtonAction={toggleSecondModal}
					onContinueButtonClick={toggleFourthModal}
				/>
			)}
			{fourthModalVisible && (
				<OnboardingOrgChart
					hasTutorial
					modalVisible={fourthModalVisible}
					closeButtonAction={onCancel}
					modalWrapperStyle={{ width: '100%', height: '100%' }}
					shadowedContainerStyle={{ margin: '20% 10% 20% 10%', minWidth: '1110px', minHeight: '725px', maxHeight: '90vh' }}
					childrenWrapperStyle={{ margin: '0 2% 0 10%', minHeight: '725px', maxHeight: '90%' }}
					onCancel={() => {
						toggleNoModal();
					}}
					onPreviousButtonAction={toggleThirdModal}
					onContinueButtonPressed={toggleFifthModal}
				/>
			)}
			{fifthModalVisible && (
				<OnboardingNewProcess
					loading={loading}
					title={`5. Create a ${APP_NAME} Workflow `}
					subtitle={`Workflows are central and one of the most important parts in ${APP_NAME}. They are steps of one of your organization's many processes. ${APP_NAME} will help make these workflows accessible to the organization. Let’s create a workflow with the name of My First Workflow (or any other name you like, you can edit this later). After typing the name press Create in the right of this page`}
					onPreviousButtonAction={toggleFourthModal}
					onSkiputtonPressed={() => {
						setLoading(true);
						startTrial()
							.pipe(take(1))
							.subscribe({
								next: () => {
									setLoading(false);
									setSkipped(true);
									toggleLastModal();
								},
							});
					}}
					onContinueButtonPressed={() => {
						setLoading(true);
						getFieldTypes()
							.pipe(take(1))
							.subscribe({
								next: () => {
									getAllComponentBlueprints(CLEAR_TO_SET_DATA)
										.pipe(first())
										.subscribe({
											complete: () => {
												setLoading(false);
												dispatch(setMessageBar({ show: false, message: '' }));
												toggleSixthModal();
												setInProcContext({ type: pat.unsetSelectedStep });
												setInProcContext({ type: pat.setAllSteps, value: [] });
											},
										});
								},
							});
					}}
				>
					<div className={cls.subheadingContainer_III} style={{ flexDirection: 'column' }}>
						<p style={{ color: 'green', fontSize: '16px' }}>{'Note: You can opt to skip this step and use the predefined templates in Add New Workflow'}</p>
					</div>
				</OnboardingNewProcess>
			)}
			{sixthModalVisible && (
				<OnboardingNewBlueprint
					title={'6. Create the Main List Component '}
					subtitle={`A component is a way for your users to input or view information. In forms these are fields that can hold a value. Start by typing Order Listing in the Name input box. After typing the name let's create several fields.\n In the Fields Selection, click the box, a drop down list should appear, look for and click Text in Input. Text should appear in Selected Fields. Click the settings icon and 
					change the Input name for Text to Sales ID then Save. Create two more Text fields, now named Status and Total Qty. Status is a regular text field while Total Qty text is a special key in the system. It allows you to compute automatically quantities used in transaction type workflows. Now, add an Info field, name to 'Order Listing', click settings, and click the Output to List dropdown. Select a prebuilt 
					component, Customers, select Customer ID for the key to use. Select Customer ID and Customer Name as the fields to display. When used in a workflow, the Info field will check the Customer database for a selected record and display both Name and ID. Now click Save to finalize this component.`}
					onContinueButtonPressed={() => {
						getAllComponentBlueprints(CLEAR_TO_SET_DATA).pipe(take(1)).subscribe({});
						dispatch(setMessageBar({ show: false, message: '' }));
						toggleSeventhModal();
						setBpContext({ type: bcat.resetSelectedBlueprintComponent });
					}}
				></OnboardingNewBlueprint>
			)}
			{seventhModalVisible && (
				<OnboardingNewBlueprint
					title={'7. Create the Orders Component '}
					subtitle={
						'Let us create your second component. In Name, type My First Orders. In the Fields Selection, add two fields: a Record and a Number. Name the Record component with Product ID, select Product as the Component where to perform lookup. Values to output should be Product Name and Field value to save as output Product ID. Interface is Dropdown. Select Yes to Show additional options and select Item Code as the Map this field to item field \
					Click Save. For Numbers, name it as Qty and map it to Quantity. Save. Add another component field, Transaction List, which is found in Outputs. This component will display all items selected. Leave the name as is. In settings, select Product ID as the Field to map with Item Code value. Click Save. Then create the component'
					}
					onContinueButtonPressed={() => {
						getAllComponentBlueprints(CLEAR_TO_SET_DATA).pipe(take(1)).subscribe({});
						dispatch(setMessageBar({ show: false, message: '' }));
						toggleEightModal();
						setInProcContext({ type: pat.addNewStep, value: selectedProcess.id });
						setBpContext({ type: bcat.resetSelectedBlueprintComponent });
					}}
				/>
			)}
			{eightModalVisible && (
				<OnboardingNewBlueprint
					title={'8. Create the Finalize Details Component '}
					subtitle={
						'Let us create our third component. In Selected Fields, look for ID in Inputs and name it Sales ID. Click settings and place SalesNo# as the ID series to generate. Add a Date field and name it Delivery Date. Add a Longtext field with the name Remarks. And finally add a Status field. Name it Status. Click Save to Create the component'
					}
					onContinueButtonPressed={() => {
						getAllComponentBlueprints(CLEAR_TO_SET_DATA).pipe(take(1)).subscribe({});
						dispatch(setMessageBar({ show: false, message: '' }));
						toggleNinthModal();
					}}
				/>
			)}
			{ninthModalVisible && (
				<OnboardingNewStep
					title={'9. Create first step '}
					subtitle={
						"We will create our first step. This step will show your users a list of orders that have been previously been created. For this we will need to add a list object that we will create out of the list component that you earlier created. Type a name for this step, i suggest something like 'Orders List' that will tell your users this step will list all orders. Add a description as well. For step number, \
					type 1 in the box. To add that list, we will go to the 'Components to be used' and you will need to click 'Attach Component' and select from the drop down after Custom, the Order Listing component you created (You may see other predinfed components in the dropdown, so make sure to select the one you created by checking the fields included). Select it and click the check button. Then look for the 'Use as Transactions List' slider and set it to YES. By doing this the step will create a Main List component that has columns based on the Orders List component. You may now SAVE "
					}
					onContinueButtonPressed={() => {
						toggleTenthModal();
						dispatch(setMessageBar({ show: false, message: '' }));
						setInProcContext({ type: pat.addNewStep, value: selectedProcess.id });
					}}
				/>
			)}
			{tenthModalVisible && (
				<OnboardingNewStep
					title={'10. Create second step '}
					subtitle={
						"We will create our second step which we will name 'Customer Info'. You may describe this step as 'Input the customer details'. This will be step number 2, which means you need to connect it to a previous step. Click the Connect Step To drop down and select the previous step \
					you created which is 'Orders List'. Go to Components to be used, click Attach Component and select from the drop down, in Templates, the Customer Selection component. This is a template component and it contains fields relevant to Customers selection. Select it and click the check button. No need to change any slider settings. You may now SAVE "
					}
					onContinueButtonPressed={() => {
						dispatch(setMessageBar({ show: false, message: '' }));
						toggleEleventhModal();
						setInProcContext({ type: pat.addNewStep, value: selectedProcess.id });
					}}
				/>
			)}
			{eleventhModalVisible && (
				<OnboardingNewStep
					title={'11. Create third step '}
					subtitle={
						"We will create our third step which we will name 'Orders'. You may describe this step as 'Input the customer orders'. This will be step number 3, which means you need to set a connection Step. Click the drop down and select the previous step \
				you created which is 'Customer Info'. Go to Components to be used, click Attach Component and select from the drop down the My First Orders component you created. Select it and click the check button. Then look for the 'Allow multiple inputs?' slider and set it to YES. This \
				will allow the step to receive multiple records having the same fields. You may now SAVE "
					}
					onContinueButtonPressed={() => {
						dispatch(setMessageBar({ show: false, message: '' }));
						toggleTwelvthModal();
						setInProcContext({ type: pat.addNewStep, value: selectedProcess.id });
					}}
				/>
			)}
			{twelvthModalVisible && (
				<OnboardingNewStep
					title={'12. Create last step '}
					subtitle={
						"We will create our last step which we will name 'Finish Orders'. You may describe this step as 'Click Save to finalize your order. This is step number 4, set connecting step to 'My First Orders', which is the step we previously created. \
				Click Attach Component and select from Custom the Finalize Details Component from step 8. Set 'Is this the final step of this workflow' slider to YES. You may now SAVE "
					}
					onContinueButtonPressed={() => {
						dispatch(setMessageBar({ show: false, message: '' }));
						toggleThirteenthModal();
					}}
				/>
			)}
			{thirteenthModalVisible && (
				<OnboardingNewModule
					title={'13. Assign your Workflow to a Module '}
					subtitle={`Modules are the accessible areas of ${APP_NAME} to users. With Modules, you can gather the info that you set in your steps and components. Provide a name for \
					the module (ie Sales Order). For the output title name, click the drop down and look for the ID field you created several steps before. Now click Save. After this you will be redirected to the visual representation of workflow. You can review the steps, components associated and other. To begin using the module \
					click Close, log in to your profile and look for the module link. You can then press the Add button to create your first transaction.  `}
					onContinueButtonPressed={() => {
						startTrial()
							.pipe(take(1))
							.subscribe({
								next: () => {
									dispatch(setMessageBar({ show: false, message: '' }));
									toggleLastModal();
								},
							});
					}}
				/>
			)}

			{lastModalVisible && (
				<Intro
					title={`You have now completed the setup for ${APP_NAME}.`}
					modalVisible={lastModalVisible}
					shadowedContainerStyle={{ width: '56%', height: '60%' }}
					modalWrapperStyle={{ width: '100%', height: '100%' }}
					loading={loading}
					childrenWrapperStyle={{}}
					onContinueButtonAction={() => {
						setLoading(true);
						dispatch(setMessageBar({ show: false, message: '' }));
						userLogout()
							.pipe(first(1))
							.subscribe({
								next: () => navigate('/login'),
								error: () => navigate('/login'),
								complete: () => navigate('/login'),
							});
						navigate('/login');
					}}
					onCancel={() => {}}
				>
					<div className={cls.subheadingContainer_III} style={{ flexDirection: 'column' }}>
						<p>
							{skipped
								? `You can create your first workflow by using the predefined workflows available. Click the Workflow link on the uppermost right part of the page and select Add New Workflow. Select one of the predefined templates, view the steps and then press the Create button.`
								: `Please find time to review the workflow, components, steps and module you have created (if not skipped). You can make revisions on the next screen. To edit steps, click the box of target step you wish to edit. \
							To edit components and modules, buttons are available on the upper right side. At this point the module should be available to the admin only. You can click the Workflow button, and look for 'Show workflow to positions' \
							and select your target positions that you want this module and workflow available. You may click Close and Dashboard to navigate the rest of ${APP_NAME}. Your module should be visible on the left side menus. You can return to this screen anytime by clicking Workflow on the upper right menus.`}
						</p>
						<br />
						<p>{`You will need to sign in again after you press the Continue Button.`}</p>
						<br />
						<p>{`Thank you for choosing ${APP_NAME} and hoping we will have a successful partnership and relationship`}</p>
					</div>
				</Intro>
			)}
			{positionModalVisible && (
				<AddNewPositionModal
					hasTutorial
					modalVisible={positionModalVisible}
					backButtonAction={toggleThirdModal}
					shadowedContainerStyle={{ width: '100%', height: '100%' }}
					modalWrapperStyle={{ width: '100%', height: '100%', overflow: undefined }}
					childrenWrapperStyle={{ margin: '0 2% 0 10%' }}
					previewOrgChartClick={toggleTwelvthModal}
					onContinueButtonAction={toggleFourthModal}
					onCancel={onCancel}
				/>
			)}
		</div>
	);
};

export default OnboardingModals;
